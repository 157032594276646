.animated-logo {
  width: 120px;
  height: 32px;
}

.logo-path {
  fill: black;

  mask-image: linear-gradient(
                  to right,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(255, 255, 255, 0.2) 20%,
                  rgba(255, 255, 255, 0.6) 40%,
                  rgba(255, 255, 255, 1) 50%,
                  rgba(255, 255, 255, 0.6) 60%,
                  rgba(255, 255, 255, 0.2) 80%,
                  rgba(0, 0, 0, 0) 100%
  );
  -webkit-mask-image: linear-gradient(
                  to right,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(255, 255, 255, 0.2) 20%,
                  rgba(255, 255, 255, 0.6) 40%,
                  rgba(255, 255, 255, 1) 50%,
                  rgba(255, 255, 255, 0.6) 60%,
                  rgba(255, 255, 255, 0.2) 80%,
                  rgba(0, 0, 0, 0) 100%
  );

  mask-size: 300% 100%;
  -webkit-mask-size: 300% 100%;
  mask-position: 0% 0%;
  -webkit-mask-position: 0% 0%;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;

  animation: gradientFill 3s linear infinite;
  -webkit-animation: gradientFill 3s linear infinite;
}

@keyframes gradientFill {
  0% {
    mask-position: -150% 0%;
    -webkit-mask-position: -150% 0%;
  }
  100% {
    mask-position: -150% 0%;
    -webkit-mask-position: 150% 0%;
  }
}
